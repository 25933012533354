import ocean from "../../assets/img/ocean-horizon.jpg";
import styled from "styled-components";

export const Div = styled.div`
  width: 100%;
  padding: 20px;
  background-image: url("${ocean}");
  background-size: cover;
  background-position: center;
  background-position-y: 30%;
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  background-color: var(--Color-primary);
  color: #fff;
  margin: 15px;
  font-size: 4em;

  img {
    height: 100%;
  }

  .sc-papXJ,
  .yXnOA,
  .rec,
  .rec-arrow,
  .rec,
  .rec-arrow-left {
    background-color: var(--Color-primary);
  }
`;

import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState, useContext } from "react";
import { ListContext } from "../../Provider/List";
import { Header } from "../../Components/Header";
import { Div, Main } from "./styles";
import photo from "../../assets/img/camera.png";
import { PaginatedItems } from "../../Components/Pagination";
import { Input, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Products = () => {
  const [search, setSearch] = useState();
  const [category, setCategory] = useState("Todos");
  const { filterList, searchList, updatedOffSet } = useContext(ListContext);

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const searchInput = () => {
    searchList(search);
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      searchInput();
    }
  };

  return (
    <>
      <Header />
      <Main>
        <Div>
          <figure id="Products">
            <img src={photo} alt="Imagem do produto" />
            <h1>Produtos</h1>
          </figure>
          <FormControl variant="standard">
            <InputLabel htmlFor="input-with-icon-adornment"></InputLabel>
            <Input
              id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              onChange={(event) => {
                setSearch(event.target.value);
                updatedOffSet();
              }}
              onKeyDown={handleKeypress}
            />
          </FormControl>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="category"
                onChange={handleChange}
              >
                <MenuItem
                  value={"Todos"}
                  onClick={() => {
                    filterList("Todos");
                    updatedOffSet();
                  }}
                >
                  Todos
                </MenuItem>
                <MenuItem
                  value={"Salvatagem"}
                  onClick={() => {
                    filterList("Salvatagem");
                    updatedOffSet();
                  }}
                >
                  Salvatagem
                </MenuItem>
                <MenuItem
                  value={"Admiralty"}
                  onClick={() => {
                    filterList("Admiralty");
                    updatedOffSet();
                  }}
                >
                  Admiralty
                </MenuItem>
                <MenuItem
                  value={"Ativa"}
                  onClick={() => {
                    filterList("Ativa");
                    updatedOffSet();
                  }}
                >
                  Ativa
                </MenuItem>
                <MenuItem
                  value={"IMO"}
                  onClick={() => {
                    filterList("IMO");
                    updatedOffSet();
                  }}
                >
                  IMO
                </MenuItem>
                <MenuItem
                  value={"DHN"}
                  onClick={() => {
                    filterList("DHN");
                    updatedOffSet();
                  }}
                >
                  DHN
                </MenuItem>
                <MenuItem
                  value={"EPI"}
                  onClick={() => {
                    filterList("EPI");
                    updatedOffSet();
                  }}
                >
                  EPI
                </MenuItem>
                <MenuItem
                  value={"ICS"}
                  onClick={() => {
                    filterList("ICS");
                    updatedOffSet();
                  }}
                >
                  ICS
                </MenuItem>
                <MenuItem
                  value={"ITU"}
                  onClick={() => {
                    filterList("ITU");
                    updatedOffSet();
                  }}
                >
                  ITU
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Div>
        <PaginatedItems itemsPerPage={16} />
      </Main>
    </>
  );
};

export default Products;

import { useNavigate } from "react-router-dom";
import { Footer } from "../../Components/Footer";
import { Header } from "../../Components/Header";
import { Main } from "./styles";
import { CarouselComponent } from "../../Components/Carrossel";
import imo from "../../assets/img/imo.jpg";
import service from "../../assets/img/service.png";
import support from "../../assets/img/support.png";
import carga from "../../assets/img/carga.jpg";
import plataforma from "../../assets/img/plataforma.jpg";

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <Main>
        <CarouselComponent />
        <section id="sobre">
          <div id="empresa">
            <div>
              <h1>A empresa</h1>
              <p>
                "Somos uma empresa há mais de 10 anos no mercado marítimo
                offshore, uma entre as 3 distribuidoras oficiais da IMO
                (International Maritime Organization) espalhadas pelo Brasil,
                credenciada como agente de vendas da DHN (Diretoria de
                Hidrografia e Navegação) para o fornecimento e atualização de
                cartas e publicações náuticas. Fornecemos materiais de qualidade
                com certificados, excelentes preços e prazos com o objetivo de
                ser coerente com o mercado, transparentes e corretos com os
                clientes que buscam nossos serviços. Temos como prioridade
                oferecer um pronto atendimento sempre entregando solução e
                qualidade."
              </p>
            </div>
            <figure>
              <a href="https://www.imo.org/en" target="_blank" rel="noreferrer">
                <img src={imo} alt="Logo da IMO" />
              </a>
            </figure>
          </div>
          <figure id="empresaImg">
            <img src={carga} alt="Navio de carga" />
          </figure>
        </section>
        <section id="sobre">
          <div id="empresa">
            <div>
              <h1>Missão</h1>
              <p>
                Atender com excelência o setor marítimo, oferecendo soluções
                especializadas e de alta qualidade que respondam com prontidão
                às necessidades e urgências dos nossos clientes, com
                transparência e empatia.
              </p>
            </div>
          </div>
          <div id="empresa">
            <div>
              <h1>Visão</h1>
              <p>
                Ser a escolha preferencial no mercado offshore, reconhecida pela
                confiança que transmitimos, pela excelência de nossos produtos e
                serviços, e pelo compromisso com a transparência, o atendimento
                ágil e a compreensão das necessidades específicas de cada
                cliente.
              </p>
            </div>
          </div>
        </section>
        <section id="sobre">
          <figure id="empresaImg">
            <img src={plataforma} alt="Plataforma" />
          </figure>
          <div id="empresa">
            <div>
              <h1>Valores</h1>
              <ul>
                <li>
                  Qualidade e Segurança: Comprometidos com padrões rigorosos de
                  qualidade e segurança para garantir que nossos serviços
                  atendam e superem as expectativas.
                </li>
                <li>
                  Responsabilidade Ambiental: Trabalhar de maneira consciente,
                  minimizando impactos ambientais e buscando soluções
                  sustentáveis para o setor marítimo.
                </li>
                <li>
                  Colaboração e Respeito: Criar um ambiente onde todos se sintam
                  valorizados, incentivando a colaboração e promovendo o
                  bem-estar e a felicidade da equipe.
                </li>
                <li>
                  Transparência e Confiança: Manter uma comunicação aberta e
                  honesta com nossos clientes, fornecedores e colaboradores,
                  fortalecendo relações de confiança mútua.
                </li>
                <li>
                  Inovação e Agilidade: Estar sempre atentos às novas
                  tecnologias e prontos para adaptar nossos serviços às
                  necessidades e urgências dos clientes de forma eficiente.
                </li>
              </ul>
            </div>
          </div>
        </section>
        <article>
          <h1>BarraMar</h1>
          <h2>Cartas e publicações náuticas</h2>
          <p>Pronto atendimento com melhores preços e prazos de entrega.</p>
        </article>
        <section id="info">
          <div>
            <figure>
              <img src={service} alt="Ver os serviços" />
            </figure>
            <h3>SERVIÇOS</h3>
            <p>
              Atualização de Cartas Náuticas • Atualização de Publicações:
              Roteiros, Lista de Faróis, Catálogos de Cartas e Publicações,
              Lista de Auxílios-Rádio, Lista de Sinais Cegos • Reparo e Aferição
              de Barômetro • Reparo e Compensação de Agulha Magnética
            </p>
            <button onClick={() => navigate("/Produtos")}>Produtos</button>
          </div>
          <div>
            <figure>
              <img src={support} alt="Ver os conatatos" />
            </figure>
            <h3>SUPORTE</h3>
            <span>Tels.: 55 (21) 3021-3886 / 2209-4257 / 97085-0986</span>
            <span>Estrada do Rio Grande, 1621 - Taquara</span>
            <div>
              <a href="mailto: vendas@barramarnautica.com.br?subject=subject text">
                vendas@barramarnautica.com.br
              </a>
              <a href="mailto: barramar.contato@barramarnautica.com.br?subject=subject text">
                barramar.contato@barramarnautica.com.br
              </a>
              <a href="mailto: financeiro@barramarnautica.com.br?subject=subject text">
                financeiro@barramarnautica.com.br
              </a>
            </div>
            <button onClick={() => navigate("/Contato")}>Fale Conosco</button>
          </div>
        </section>
        <article>
          <h1>Política da Qualidade</h1>
          <h2>
            A Barramar em suas atividades atuando no setor marítimo e offshore,
            compromete-se a:
          </h2>
          <div>
            <ul>
              <li>
                Atender aos requisitos legais aplicáveis às nossas atividades,
                aos requisitos do cliente e a outros subscritos; Atender com
                prontidão às necessidades e urgências de nossos clientes,
                garantindo soluções personalizadas, ágeis e eficientes;
              </li>
              <li>Capacitar continuamente os colaboradores;</li>
              <li>
                Garantir resultados por meio do cumprimento dos
                indicadores estabelecidos.
              </li>
            </ul>
            <ul>
              <li>
                Promover a empatia e a transparência em todas as interações,
                reforçando a confiança e a satisfação de nossos clientes;
              </li>
              <li>
                Atender as expectativas dos clientes, por meio da melhoria
                contínua da qualidade dos produtos e serviços;
              </li>
              <li>
                Melhorar continuamente para aumentar o desempenho do Sistema de
                Gestão da Qualidade;
              </li>
            </ul>
          </div>
        </article>
      </Main>
      <Footer />
    </>
  );
};

export default Home;

import BACHART from "../assets/img/admiralty/BA CHART.jpg";
import BA566 from "../assets/img/admiralty/BA566.jpg";
import APITOSOLAS from "../assets/img/ativa/APITO SOLAS.jpg";
import APITO from "../assets/img/ativa/APITO.jpg";
import BOIAARINQUE from "../assets/img/ativa/BOIA ARINQUE.jpg";
import BOIACLASSEI from "../assets/img/ativa/BOIA CLASSE I 70CM.jpg";
import BOIACLASSEII from "../assets/img/ativa/BOIA CLASSE II 50CM.jpg";
import BOIACLASSEII60 from "../assets/img/ativa/BOIA CLASSE II 60CM.jpg";
import BOIACLASSEIII50 from "../assets/img/ativa/BOIA CLASSE III 50CM.jpg";
import BOIACLASSEIII60 from "../assets/img/ativa/BOIA CLASSE III 60CM.jpg";
import CABODERETINIDA from "../assets/img/ativa/CABO DE RETINIDA.jpg";
import COLETECLASSEI from "../assets/img/ativa/COLETE CLASSE I SOLAS 150N.jpg";
import COLETECLASSEII from "../assets/img/ativa/COLETE CLASSE II CANGA.jpg";
import COLETECLASSEIIJ from "../assets/img/ativa/COLETE CLASSE II JALECO.jpg";
import COLETECLASSEIV from "../assets/img/ativa/COLETE CLASSE IV JALECO BaSICO.jpg";
import COLETECLASSEIVR from "../assets/img/ativa/COLETE CLASSE IV JALECO COM REFLETIVO.jpg";
import COLETECLASSEIVRE from "../assets/img/ativa/COLETE CLASSE IV RESGATE.jpg";
import COLETECLASSEIVV from "../assets/img/ativa/COLETE CLASSE IV VERTICAL.jpg";
import COLETESOLDADOR from "../assets/img/ativa/COLETE SOLDADOR.jpg";
import D1501 from "../assets/img/dhn/1501.jpg";
import ARTENAVAL1 from "../assets/img/dhn/ARTE NAVAL 1.jpg";
import ARTENAVAL2 from "../assets/img/dhn/ARTE NAVAL 2.jpg";
import RIPEAM72 from "../assets/img/dhn/RIPEAM-72.jpg";
import M3 from "../assets/img/epi/3M.png";
import ANSELL from "../assets/img/epi/ANSELL.png";
import BRASCAMP from "../assets/img/epi/BRASCAMP.png";
import BSB from "../assets/img/epi/BSB.png";
import DANNY from "../assets/img/epi/DANNY.png";
import DUPONT from "../assets/img/epi/DUPONT.png";
import FUJIWARA from "../assets/img/epi/FUJIWARA.png";
import HERCULES from "../assets/img/epi/HERCULES.png";
import HONEYWELL from "../assets/img/epi/HONEYWELL.png";
import MARLUVAS from "../assets/img/epi/MARLUVAS.png";
import MSA from "../assets/img/epi/MSA.png";
import MUCAMBO from "../assets/img/epi/MUCAMBO.png";
import ORION from "../assets/img/epi/ORION.png";
import TAYCO from "../assets/img/epi/TAYCO.png";
import VOLK from "../assets/img/epi/VOLK.png";
import BRACOL from "../assets/img/epi/BRACOL.jpg";
import HAWSAVLIS from "../assets/img/epi/HAWS AVLIS.jpg";
import BridgeProceduresGuideSixth from "../assets/img/ics/Bridge Procedures Guide Sixth.png";
import GuidetoHelicopterShipOperationsFifth from "../assets/img/ics/Guide to Helicopter Ship Operations Fifth.png";
import DrugTrafficking from "../assets/img/ics/Drug Trafficking.jpg";
import EngineRoomProceduresGuide from "../assets/img/ics/Engine Room Procedures Guide.jpg";
import ISGOTT from "../assets/img/ics/ISGOTT.jpg";
import ISMGuidelines from "../assets/img/ics/ISM Guidelines.jpg";
import LIQUEFIEDGAS from "../assets/img/ics/LIQUEFIED GAS.jpg";
import MARITIMESECURITY from "../assets/img/ics/MARITIME SECURITY.jpg";
import PerilatSea from "../assets/img/ics/Peril at Sea.jpg";
import ShiptoShipTransfer from "../assets/img/ics/Ship to Ship Transfer.jpg";
import THEILOMARITIMELABOURCONVENTION from "../assets/img/ics/THE ILO MARITIME LABOUR CONVENTION.jpg";
import TUGUSE from "../assets/img/ics/TUG USE.jpg";
import ListIV from "../assets/img/itu/List IV - List of Coast Stations and Special Service Stations, 2021.jpg";
import ListV from "../assets/img/itu/List V - List of Ship Stations and Maritime Mobile Service Identity Assignments - 2022.jpg";
import ListVIII from "../assets/img/itu/List VIII List of International Monitoring Stations.jpg";
import Manual from "../assets/img/itu/Manual for Use by the Maritime Mobile and Maritime Mobile-Satellite Services (Maritime Manual), 2020.jpg";
import RadioRegulations from "../assets/img/itu/Radio Regulations, 2020.jpg";
import FireTestProcedures from "../assets/img/imo/2010 Fire Test Procedures (FTP) Code , 2012 Edition IC844E.jpg";
import BWMConvention from "../assets/img/imo/BWM Convention BWMS Code with Guidelines for Implementation, 2018 IA621E.jpg";
import CargoStowage from "../assets/img/imo/Cargo Stowage Securing (CSS), 2021 Ed IC292E.jpg";
import CodeonIntact from "../assets/img/imo/Code on Intact Stability 2008, 2020 Edition IC874E.jpg";
import GMDSSManual from "../assets/img/imo/GMDSS Manual, 2019 Edition II970E.jpg";
import IAMSARVolumeI from "../assets/img/imo/IAMSAR Volume I, 2022 Edition IK960E.jpg";
import IAMSARVolumeII from "../assets/img/imo/IAMSAR Volume II, 2022 Edition IH961E.jpg";
import IAMSARVolumeIII from "../assets/img/imo/IAMSAR Volume III, 2022 Edition IK962E.jpg";
import IBCCode from "../assets/img/imo/IBC Code, 2020 Edition IE100E.jpg";
import IMOVEGA from "../assets/img/imo/IMO VEGA SVEGA26.jpg";
import IB994E from "../assets/img/imo/International Code of Signals, 2005 Edition IB994E.jpg";
import ID117E from "../assets/img/imo/ISM Code with Guidelines, 2018 Edition ID117E.jpg";
import IE982E from "../assets/img/imo/Life-Saving Appliances inc. LSA Code, 2017 Edition IE982E.jpg";
import IC701E from "../assets/img/imo/Load Lines, consolidated Edition, 2021 IC701E.jpg";
import MARPOL from "../assets/img/imo/MARPOL Consolidated Edition, 2022.jpg";
import IA289E from "../assets/img/imo/OSV Chemical Code, 2018 Edition IA289E.jpg";
import IE650E from "../assets/img/imo/Procedures for port State control 2021, 2022 Edition IE650E.jpg";
import IB116E from "../assets/img/imo/Security Guide ISPS Code, 2021 IB116E.jpg";
import IH927E from "../assets/img/imo/ShipsRouteing, 2019 Edition IH927E.jpg";
import IA987E from "../assets/img/imo/SMCP Publication and CD, 2005 Edition IA987E.jpg";
import IG110E from "../assets/img/imo/SOLAS Consolidated Edition, 2020 IG110E.jpg";
import ID938E from "../assets/img/imo/STCW including 2010 Manila Amendments, 2017 Edition ID938E.jpg";
import BANDEIRABRASIL from "../assets/img/salvatagem/BANDEIRA BRASIL.jpg";
import BandeirasAlfabeticas from "../assets/img/salvatagem/Bandeiras Alfabeticas.jpg";
import BussolaRitchie from "../assets/img/salvatagem/Bussola Ritchie.jpg";
import Cabos from "../assets/img/salvatagem/Cabos.jpg";
import cartacompasso from "../assets/img/salvatagem/carta e compasso (imagem que vai passar no site).jpg";
import CobertorManta from "../assets/img/salvatagem/Cobertor Manta Anti-Chama 180x160cm.jpg";
import ColeteSalva from "../assets/img/salvatagem/Colete Salva vidas Work Vest.jpg";
import CompassoPontaSeca from "../assets/img/salvatagem/Compasso Ponta Seca.jpg";
import Faca from "../assets/img/salvatagem/FACA.jpg";
import SRLB1 from "../assets/img/salvatagem/Facho Holmes com Suporte SR-LB1.jpg";
import L160 from "../assets/img/salvatagem/Facho Holmes Daniamant L160.jpg";
import L163 from "../assets/img/salvatagem/Facho Holmes Daniamant L163.jpg";
import FachoHolmesLonako from "../assets/img/salvatagem/FACHO HOLMES LONAKO.jpg";
import Fitas from "../assets/img/salvatagem/Fitas para Demarcacao.jpg";
import LanternaBrightStar from "../assets/img/salvatagem/Lanterna Bright Star.jpg";
import RadiosMotorola from "../assets/img/salvatagem/Radios Motorola.jpg";
import RadiosMotorola1 from "../assets/img/salvatagem/Radios Motorola(1).jpg";
import IFI715 from "../assets/img/salvatagem/Sinal Fumigeno Flutuante Iluminativo Indios IFI-715.jpg";
import IFS205VM from "../assets/img/salvatagem/Sinal Manual Estrela Vermelha IFS-205VM.jpg";
import Empresas from "../assets/img/salvatagem/Bandeiras das Empresas de Navegacao.png";
import BinoculoSteiner from "../assets/img/salvatagem/Binoculo Steiner Marine 7 x 50 mm.png";
import Cartucho from "../assets/img/salvatagem/Cartucho Lanca retinida Ikaros.png";
import Escada from "../assets/img/salvatagem/Escada de Aluminio Gangway Prancha Embarque Wharf Ladder.png";
import EscadaQuebra from "../assets/img/salvatagem/ESCADA QUEBRA PEITO.png";
import Esquadro from "../assets/img/salvatagem/ESQUADRO.png";
import Fitas2 from "../assets/img/salvatagem/Fitas (imagem para passar na area de fitas).png";
import fitas3 from "../assets/img/salvatagem/fitas (imagem para passar no site).PNG";
import Fumigeno from "../assets/img/salvatagem/Fumigeno Flutuante WescomPWSS Laranja 3min MK8 9537000.png";
import Imagemfitas from "../assets/img/salvatagem/Imagem para ficar na area das fitas.png";
import LampadaACR from "../assets/img/salvatagem/Lampada ACR HL8-10 HemiLight3.png";
import LampadaRL5 from "../assets/img/salvatagem/Lampada Daniamant Dan RL5.png";
import PN346200 from "../assets/img/salvatagem/Lanca Retinida NammoHanssonIkaros Cartucho PN346200.png";
import Lanca250 from "../assets/img/salvatagem/Lanca Retinida WescomPWSS Linethrower 250.png";
import MICROFONE from "../assets/img/salvatagem/MICROFONE AUTOFALANTE PARA RADIOTELEFONE VHF (ICOM HM-167).png";
import MICROFONE2 from "../assets/img/salvatagem/MICROFONE AUTOFALANTE PARA RADIOTELEFONE VHF (ICOM HM-213).png";
import ICM73 from "../assets/img/salvatagem/Radio Maritimo Portatil VHF 6W Slim IC-M73.png";
import ICM424G from "../assets/img/salvatagem/Radio Maritimo VHF 25W ICOM IC-M424G.png";
import GM1600 from "../assets/img/salvatagem/Radio Portatil VHF GMDSS ICOM IC-GM1600.png";
import M37 from "../assets/img/salvatagem/RADIO VHF PORTATIL ICOM IC-M37.png";
import Hidrostatica from "../assets/img/salvatagem/Valvula Hidrostatica ACR 9490 Hydrofix para Epirb.png";
import EpirbH20E from "../assets/img/salvatagem/Valvula Hidrostatica Hammar para Epirb H20E.webp";
import EpirbH20R from "../assets/img/salvatagem/Valvula Hidrostatica Hammar para Balsa H20R.webp";
import IFF203LJ from "../assets/img/salvatagem/Sinal Fumigeno Flutuante Laranja IFF-203LJ.webp";
import IMF707 from "../assets/img/salvatagem/Maleta Estanque Para Sinalizadores Indios IMF-707.webp";
import VM202 from "../assets/img/salvatagem/Facho Manual Luz Vermelha Indios  IFL-202VM.webp";
import BinoculoNautika from "../assets/img/salvatagem/Binoculo Nautika.webp";
import ancoraGarateia from "../assets/img/salvatagem/Ancora Garateia.webp";
import IMSBC2 from "../assets/img/imo/IMSBC.jpg";
import IMDGCode3 from "../assets/img/imo/IMDG Code Vol 1 e. 2.jpg";
import IMDGSupplement4 from "../assets/img/imo/IMDG Supplement.jpg";

export const ListProducts = [
  {
    name: "IMDG Supplement",
    category: "IMO",
    img: IMDGSupplement4,
  },
  {
    name: "IMDG Code Vol 1 e. 2",
    category: "IMO",
    img: IMDGCode3,
  },
  {
    name: "IMSBC",
    category: "IMO",
    img: IMSBC2,
  },
  {
    name: "2010 Fire Test Procedures (FTP) Code , 2012 Edition IC844E",
    category: "IMO",
    img: FireTestProcedures,
  },
  {
    name: "BWM Convention - BWMS Code with Guidelines for Implementation, 2018 IA621E",
    category: "IMO",
    img: BWMConvention,
  },
  {
    name: "Cargo Stowage - Securing (CSS), 2021 Ed IC292E",
    category: "IMO",
    img: CargoStowage,
  },
  {
    name: "Code on Intact Stability 2008, 2020 Edition IC874E",
    category: "IMO",
    img: CodeonIntact,
  },
  {
    name: "GMDSS Manual, 2019 Edition II970E",
    category: "IMO",
    img: GMDSSManual,
  },
  {
    name: "IAMSAR Volume 1, 2022 Edition IK960E",
    category: "IMO",
    img: IAMSARVolumeI,
  },
  {
    name: "IAMSAR Volume 2, 2022 Edition IH961E",
    category: "IMO",
    img: IAMSARVolumeII,
  },
  {
    name: "IAMSAR Volume 3, 2022 Edition IK962E",
    category: "IMO",
    img: IAMSARVolumeIII,
  },
  {
    name: "IBC Code, 2020 Edition IE100E",
    category: "IMO",
    img: IBCCode,
  },
  {
    name: "IMO VEGA SVEGA26",
    category: "IMO",
    img: IMOVEGA,
  },
  {
    name: "International Code of Signals, 2005 Edition IB994E",
    category: "IMO",
    img: IB994E,
  },
  {
    name: "ISM Code with Guidelines, 2018 Edition ID117E",
    category: "IMO",
    img: ID117E,
  },
  {
    name: "Life-Saving Appliances inc. LSA Code, 2017 Edition IE982E",
    category: "IMO",
    img: IE982E,
  },
  {
    name: "Load Lines, consolidated Edition, 2021 IC701E",
    category: "IMO",
    img: IC701E,
  },
  {
    name: "MARPOL Consolidated Edition, 2022",
    category: "IMO",
    img: MARPOL,
  },
  {
    name: "OSV Chemical Code, 2018 Edition IA289E",
    category: "IMO",
    img: IA289E,
  },
  {
    name: "Procedures for port State control 2021, 2022 Edition IE650E",
    category: "IMO",
    img: IE650E,
  },
  {
    name: "Security Guide _ ISPS Code, 2021 IB116E",
    category: "IMO",
    img: IB116E,
  },
  {
    name: "Ships_ Routeing, 2019 Edition IH927E.jpg",
    category: "IMO",
    img: IH927E,
  },
  {
    name: "SMCP Publication and CD, 2005 Edition IA987E",
    category: "IMO",
    img: IA987E,
  },
  {
    name: "SOLAS Consolidated Edition, 2020 IG110E",
    category: "IMO",
    img: IG110E,
  },
  {
    name: "STCW including 2010 Manila Amendments, 2017 Edition ID938E",
    category: "IMO",
    img: ID938E,
  },
  {
    name: "Válvula Hidrostática Hammar para Epirb H20E",
    category: "Salvatagem",
    img: EpirbH20E,
  },
  {
    name: "Válvula Hidrostática Hammar para Balsa H20R",
    category: "Salvatagem",
    img: EpirbH20R,
  },
  {
    name: "Sinal Fumígeno Flutuante Laranja IFF-203LJ",
    category: "Salvatagem",
    img: IFF203LJ,
  },
  {
    name: "Maleta Estanque Para Sinalizadores Indios IMF-707",
    category: "Salvatagem",
    img: IMF707,
  },
  {
    name: "Facho Manual Luz Vermelha Indios  IFL - 202VM",
    category: "Salvatagem",
    img: VM202,
  },
  {
    name: "Binóculo Nautika",
    category: "Salvatagem",
    img: BinoculoNautika,
  },
  {
    name: "Âncora Garateia",
    category: "Salvatagem",
    img: ancoraGarateia,
  },
  {
    name: "RÁDIO VHF PORTÁTIL ICOM IC-M37",
    category: "Salvatagem",
    img: M37,
  },
  {
    name: "Válvula Hidrostática ACR 9490 Hydrofix para Epirb",
    category: "Salvatagem",
    img: Hidrostatica,
  },
  {
    name: "Fumígeno Flutuante Wescom_PWSS Laranja 3min MK8 9537000",
    category: "Salvatagem",
    img: Fumigeno,
  },
  {
    name: "Imagem para ficar na área das fitas",
    category: "Salvatagem",
    img: Imagemfitas,
  },
  {
    name: "Lâmpada ACR HL8-10 HemiLight™3",
    category: "Salvatagem",
    img: LampadaACR,
  },
  {
    name: "Lâmpada Daniamant Dan RL5",
    category: "Salvatagem",
    img: LampadaRL5,
  },
  {
    name: "Lança Retinida Nammo_Hansson_Ikaros Cartucho PN346200",
    category: "Salvatagem",
    img: PN346200,
  },
  {
    name: "Lança Retinida Wescom_PWSS Linethrower 250",
    category: "Salvatagem",
    img: Lanca250,
  },
  {
    name: "MICROFONE AUTOFALANTE PARA RADIOTELEFONE VHF (ICOM HM-167)",
    category: "Salvatagem",
    img: MICROFONE,
  },
  {
    name: "MICROFONE AUTOFALANTE PARA RADIOTELEFONE VHF (ICOM HM-213)",
    category: "Salvatagem",
    img: MICROFONE2,
  },
  {
    name: "Rádio Marítimo Portátil VHF 6W Slim IC-M73",
    category: "Salvatagem",
    img: ICM73,
  },
  {
    name: "Rádio Marítimo VHF 25W ICOM IC-M424G",
    category: "Salvatagem",
    img: ICM424G,
  },
  {
    name: "Rádio Portátil VHF GMDSS ICOM IC-GM1600",
    category: "Salvatagem",
    img: GM1600,
  },
  {
    name: "Sinal Fumígeno Flutuante Iluminativo Indios IFI-715",
    category: "Salvatagem",
    img: IFI715,
  },
  {
    name: "Sinal Manual Estrela Vermelha IFS-205VM",
    category: "Salvatagem",
    img: IFS205VM,
  },
  {
    name: "Bandeiras das Empresas de Navegação",
    category: "Salvatagem",
    img: Empresas,
  },
  {
    name: "Binóculo Steiner Marine 7 x 50 mm",
    category: "Salvatagem",
    img: BinoculoSteiner,
  },
  {
    name: "Cartucho Lança retinida Ikaros",
    category: "Salvatagem",
    img: Cartucho,
  },
  {
    name: "Escada de Alumínio Gangway – Prancha Embarque Wharf Ladder",
    category: "Salvatagem",
    img: Escada,
  },
  {
    name: "Escada Quebra Peito",
    category: "Salvatagem",
    img: EscadaQuebra,
  },
  {
    name: "Esquadro",
    category: "Salvatagem",
    img: Esquadro,
  },
  {
    name: "Fitas (imagem para passar na área de fitas)",
    category: "Salvatagem",
    img: Fitas2,
  },
  {
    name: "fitas (imagem para passar no site)",
    category: "Salvatagem",
    img: fitas3,
  },
  {
    name: "BANDEIRA BRASIL",
    category: "Salvatagem",
    img: BANDEIRABRASIL,
  },
  {
    name: "Bandeiras Alfabéticas",
    category: "Salvatagem",
    img: BandeirasAlfabeticas,
  },
  {
    name: "Bússola Ritchie",
    category: "Salvatagem",
    img: BussolaRitchie,
  },
  {
    name: "Cabos",
    category: "Salvatagem",
    img: Cabos,
  },
  {
    name: "Carta e compasso (imagem que vai passar no site)",
    category: "Salvatagem",
    img: cartacompasso,
  },
  {
    name: "Cobertor Manta Anti-Chama 180x160cm",
    category: "Salvatagem",
    img: CobertorManta,
  },
  {
    name: "Colete Salva vidas Work Vest",
    category: "Salvatagem",
    img: ColeteSalva,
  },
  {
    name: "Compasso Ponta Seca",
    category: "Salvatagem",
    img: CompassoPontaSeca,
  },
  {
    name: "Faca",
    category: "Salvatagem",
    img: Faca,
  },
  {
    name: "Facho Holmes com Suporte SR-LB1",
    category: "Salvatagem",
    img: SRLB1,
  },
  {
    name: "Facho Holmes Daniamant L160",
    category: "Salvatagem",
    img: L160,
  },
  {
    name: "Facho Holmes Daniamant L163",
    category: "Salvatagem",
    img: L163,
  },
  {
    name: "Facho Holmes Lonako",
    category: "Salvatagem",
    img: FachoHolmesLonako,
  },
  {
    name: "Fitas para Demarcação",
    category: "Salvatagem",
    img: Fitas,
  },
  {
    name: "Lanterna Bright Star",
    category: "Salvatagem",
    img: LanternaBrightStar,
  },
  {
    name: "Rádios Motorola",
    category: "Salvatagem",
    img: RadiosMotorola,
  },
  {
    name: "Rádios Motorola",
    category: "Salvatagem",
    img: RadiosMotorola1,
  },
  {
    name: "List of Coast Stations and Special Service Stations, 2021",
    category: "ITU",
    img: ListIV,
  },
  {
    name: "List of Ship Stations and Maritime Mobile Service Identity Assignments - 2022",
    category: "ITU",
    img: ListV,
  },
  {
    name: "List of International Monitoring Stations.jpg",
    category: "ITU",
    img: ListVIII,
  },
  {
    name: "Manual for Use by the Maritime Mobile and Maritime Mobile-Satellite Services (Maritime Manual), 2020",
    category: "ITU",
    img: Manual,
  },
  {
    name: "Radio Regulations, 2020",
    category: "ITU",
    img: RadioRegulations,
  },
  {
    name: "Bridge Procedures Guide Sixth",
    category: "ICS",
    img: BridgeProceduresGuideSixth,
  },
  {
    name: "Guide to Helicopter Ship Operations Fifth",
    category: "ICS",
    img: GuidetoHelicopterShipOperationsFifth,
  },
  {
    name: "Drug Trafficking",
    category: "ICS",
    img: DrugTrafficking,
  },
  {
    name: "Engine Room Procedures Guide",
    category: "ICS",
    img: EngineRoomProceduresGuide,
  },
  {
    name: "ISGOTT",
    category: "ICS",
    img: ISGOTT,
  },
  {
    name: "ISM Guidelines",
    category: "ICS",
    img: ISMGuidelines,
  },
  {
    name: "LIQUEFIED GAS",
    category: "ICS",
    img: LIQUEFIEDGAS,
  },
  {
    name: "MARITIME SECURITY",
    category: "ICS",
    img: MARITIMESECURITY,
  },
  {
    name: "Peril at Sea",
    category: "ICS",
    img: PerilatSea,
  },
  {
    name: "Ship to Ship Transfer",
    category: "ICS",
    img: ShiptoShipTransfer,
  },
  {
    name: "THE ILO MARITIME LABOUR CONVENTION",
    category: "ICS",
    img: THEILOMARITIMELABOURCONVENTION,
  },
  {
    name: "TUG USE",
    category: "ICS",
    img: TUGUSE,
  },
  {
    name: "3M",
    category: "EPI",
    img: M3,
  },
  {
    name: "ANSELL",
    category: "EPI",
    img: ANSELL,
  },
  {
    name: "BRASCAMP",
    category: "EPI",
    img: BRASCAMP,
  },
  {
    name: "BSB",
    category: "EPI",
    img: BSB,
  },
  {
    name: "DANNY",
    category: "EPI",
    img: DANNY,
  },
  {
    name: "DUPONT",
    category: "EPI",
    img: DUPONT,
  },
  {
    name: "FUJIWARA",
    category: "EPI",
    img: FUJIWARA,
  },
  {
    name: "HERCULES",
    category: "EPI",
    img: HERCULES,
  },
  {
    name: "HONEYWELL",
    category: "EPI",
    img: HONEYWELL,
  },
  {
    name: "MARLUVAS",
    category: "EPI",
    img: MARLUVAS,
  },
  {
    name: "MSA",
    category: "EPI",
    img: MSA,
  },
  {
    name: "MUCAMBO",
    category: "EPI",
    img: MUCAMBO,
  },
  {
    name: "ORION",
    category: "EPI",
    img: ORION,
  },
  {
    name: "TAYCO",
    category: "EPI",
    img: TAYCO,
  },
  {
    name: "VOLK",
    category: "EPI",
    img: VOLK,
  },
  {
    name: "BRACOL",
    category: "EPI",
    img: BRACOL,
  },
  {
    name: "HAWS AVLIS",
    category: "EPI",
    img: HAWSAVLIS,
  },
  {
    name: "1501",
    category: "DHN",
    img: D1501,
  },
  {
    name: "ARTE NAVAL 1",
    category: "DHN",
    img: ARTENAVAL1,
  },
  {
    name: "ARTE NAVAL 2",
    category: "DHN",
    img: ARTENAVAL2,
  },
  {
    name: "RIPEAM-72",
    category: "DHN",
    img: RIPEAM72,
  },
  {
    name: "BA CHART",
    category: "Admiralty",
    img: BACHART,
  },
  {
    name: "BA566",
    category: "Admiralty",
    img: BA566,
  },
  {
    name: "APITO SOLAS",
    category: "Ativa",
    img: APITOSOLAS,
  },
  {
    name: "APITO",
    category: "Ativa",
    img: APITO,
  },
  {
    name: "BOIA ARINQUE",
    category: "Ativa",
    img: BOIAARINQUE,
  },
  {
    name: "BOIA CLASSE 70CM",
    category: "Ativa",
    img: BOIACLASSEI,
  },
  {
    name: "BOIA CLASSE 50CM",
    category: "Ativa",
    img: BOIACLASSEII,
  },
  {
    name: "BOIA CLASSE 60CM",
    category: "Ativa",
    img: BOIACLASSEII60,
  },
  {
    name: "BOIA CLASSE 50CM",
    category: "Ativa",
    img: BOIACLASSEIII50,
  },
  {
    name: "BOIA CLASSE 60CM",
    category: "Ativa",
    img: BOIACLASSEIII60,
  },
  {
    name: "CABO DE RETINIDA",
    category: "Ativa",
    img: CABODERETINIDA,
  },
  {
    name: "COLETE CLASSE SOLAS 150N",
    category: "Ativa",
    img: COLETECLASSEI,
  },
  {
    name: "COLETE CLASSE CANGA",
    category: "Ativa",
    img: COLETECLASSEII,
  },
  {
    name: "COLETE CLASSE JALECO",
    category: "Ativa",
    img: COLETECLASSEIIJ,
  },
  {
    name: "COLETE CLASSE JALECO BÁSICO",
    category: "Ativa",
    img: COLETECLASSEIV,
  },
  {
    name: "COLETE CLASSE JALECO COM REFLETIVO",
    category: "Ativa",
    img: COLETECLASSEIVR,
  },
  {
    name: "COLETE CLASSE RESGATE",
    category: "Ativa",
    img: COLETECLASSEIVRE,
  },
  {
    name: "COLETE CLASSE VERTICAL",
    category: "Ativa",
    img: COLETECLASSEIVV,
  },
  {
    name: "COLETE SOLDADOR",
    category: "Ativa",
    img: COLETESOLDADOR,
  },
];

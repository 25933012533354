import React from "react";
import Carousel from "react-elastic-carousel";
import IAMSARI from "../../assets/img/imo/IAMSAR Volume I, 2022 Edition IK960E.jpg";
import IMDGSUPLEMENT from "../../assets/img/imo/IMDG Supplement.jpg";
import IMDGCODE1e2 from "../../assets/img/imo/IMDG Code Vol 1 e. 2.jpg";
import MARPOL from "../../assets/img/imo/MARPOL Consolidated Edition, 2022.jpg";
import IMDGCODE from "../../assets/img/imo/IMDG Code Vol 1.jpg";
import SOLAS from "../../assets/img/imo/SOLAS Consolidated Edition, 2020 IG110E.jpg";
import { Div, Item } from "./styles";
import "./styles.css";

export const CarouselComponent = () => {
  return (
    <Div>
      <Carousel>
        <Item>
          <img src={SOLAS} alt="SOLAS" />
        </Item>
        <Item>
          <img src={MARPOL} alt="MARPOL" />
        </Item>
        <Item>
          <img src={IAMSARI} alt="IAMSARI" />
        </Item>
        <Item>
          <img src={IMDGCODE} alt="IMDGCODE" />
        </Item>
        <Item>
          <img src={IMDGSUPLEMENT} alt="IMDGSUPLEMENT" />
        </Item>
        <Item>
          <img src={IMDGCODE1e2} alt="IMDGCODE1e2" />
        </Item>
      </Carousel>
    </Div>
  );
};

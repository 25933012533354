import { RoutesPages } from './Routers';
import GlobalStyle from './Styles/global';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Whatsapp } from './Components/Whatsapp';

function App() {
  return (
    <>
      <GlobalStyle />
      <RoutesPages />
      <ToastContainer/>
      <Whatsapp/>
    </>
  );
}

export default App;

import styled from "styled-components";

export const FooterPage = styled.footer`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--Color-primary);
  margin-top: 50px;
  padding: 10px 15px;
  z-index: 999998;

  > div {
    display: flex;
    width: 100%;
    text-align: center;
    flex-direction: column;
    gap: 15px;
    padding: 15px;

    h3 {
      color: var(--White);
    }

    div {
      display: flex;
      width: 100%;
      text-align: center;
      flex-direction: column;
      gap: 10px;
    }

    a {
      text-decoration: none;
      color: var(--White-1);

      :hover {
        color: var(--White);
      }
    }

    p {
      color: var(--White-1);
      cursor: pointer;

      :hover {
        color: var(--White);
      }
    }
  }

  @media (min-width: 425px) {
    flex-direction: row;
    justify-content: space-around;
  }

  @media (min-width: 768px) {
    z-index: 1;
  }
`;

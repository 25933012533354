import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { motion } from "framer-motion";
import { HeaderDesktop, HeaderMobile } from "./styles";
import logo from "../../assets/img/logo.png";
import menu from "../../assets/img/menu.png";
import exit from "../../assets/img/exit.png";

export const Header = () => {
  const navigate = useNavigate();
  const [container, setContainer] = useState(false);
  const [menuExit, setMenuExit] = useState(true);

  return (
    <>
      <HeaderDesktop>
        <figure onClick={() => navigate("/")}>
          <img src={logo} alt="Logo BarraMar" />
          <h1>BarraMar</h1>
        </figure>
        <nav>
          <Link to="/">Home</Link>
          {/* <Link to="/Empresa">Empresa</Link> */}
          <Link to="/Produtos">Produtos</Link>
          <Link to="/Contato">Contato</Link>
        </nav>
      </HeaderDesktop>
      <HeaderMobile>
        <figure onClick={() => navigate("/")}>
          <img id="logo" src={logo} alt="Logo BarraMar" />
        </figure>
        {menuExit && (
          <figure onClick={() => setContainer(true)}>
            <motion.img
              onClick={() => setMenuExit(false)}
              src={menu}
              alt="Menu"
              initial={{ scale: 0 }}
              animate={{ rotate: 180, scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
              whileHover={{ scale: 1.2, rotate: 90 }}
              whileTap={{
                scale: 0.8,
                rotate: -90,
                borderRadius: "100%",
              }}
            />
          </figure>
        )}
        {container && (
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
          >
            <figure onClick={() => setContainer(false)}>
              <motion.img
                onClick={() => setMenuExit(true)}
                src={exit}
                alt="Menu"
                initial={{ scale: 0 }}
                animate={{ rotate: 180, scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                }}
                whileHover={{ scale: 1.2, rotate: 90 }}
                whileTap={{
                  scale: 0.8,
                  rotate: -90,
                  borderRadius: "100%",
                }}
              />
            </figure>
            <nav>
              <Link to="/">Home</Link>
              {/* <Link to="/Empresa">Empresa</Link> */}
              <Link to="/Produtos">Produtos</Link>
              <Link to="/Contato">Contato</Link>
            </nav>
          </motion.div>
        )}
      </HeaderMobile>
    </>
  );
};

import { createContext, useState } from "react";
import { ListProducts } from "../../database";

export const ListContext = createContext();

export const ListProvider = ({ children }) => {
  const [Lists, setLists] = useState(ListProducts);
  const [itemOffset, setItemOffset] = useState(0);

  const updatedOffSet = () => {
    setItemOffset(0);
  };

  const searchList = item => {
    const searchFilter = Lists.filter(
      product =>
        product.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            item
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          ) ||
        product.category
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            item
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          )
    );

    if (item.length === 0) {
      setLists(ListProducts);
    } else {
      setLists(searchFilter);
    }
  };

  const filterList = item => {
    if (item === "Todos") {
      setLists(ListProducts);
    } else {
      const produtosFiltrados = ListProducts.filter(
        produto => produto.category.toLowerCase() === item.toLowerCase()
      );
      setLists(produtosFiltrados);
    }
  };

  return (
    <ListContext.Provider
      value={{
        Lists,
        setLists,
        filterList,
        searchList,
        updatedOffSet,
        setItemOffset,
        itemOffset,
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

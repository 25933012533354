import styled from "styled-components";

export const A = styled.a`
    position:fixed;
    width:50px;
    height:50px;
    bottom:25px;
    right:25px;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    z-index:999997;
    box-shadow: 1px 1px 2px #888;

    img{
        width: 60px;
        height: 60px;
    }
`
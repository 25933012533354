import styled from "styled-components";

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;
  margin-top: 40px;

  #Products {
    display: none;
  }
  figure {
    display: flex;
    align-items: center;
    margin-right: 10px;

    img {
      width: 30px;
    }

    h1 {
      font-family: "Lobster", cursive;
      color: var(--Black-1);
      padding-left: 10px;
    }
  }

  @media (min-width: 768px) {
    padding: 0 50px;

    #Products {
      display: flex;
    }
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1440px) {
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  #listProducts {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 0;

    li {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 300px;
      height: 330px;
      background-color: var(--Grey-1);
      color: var(--Grey-2);
      border: 5px solid var(--Grey-1);
      border-radius: 20px;
      box-sizing: border-box;
      margin: 10px 12px;
      text-align: center;
      text-align: -webkit-center;
      cursor: pointer;
      transition: 1s;

      figure {
        align-items: center;
        background: black;
        display: flex;
        width: 290px;
        height: 210px;
        justify-content: center;
        border-radius: 20px;
        img {
          border-radius: 20px;
          height: 100%;
          width: 100%;
          border: solid 3px var(--Color-primary);
          transition: 1s;
        }
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 130px;
        width: 90px;
        position: relative;
        top: -20px;
        left: -80px;
        background-color: var(--White-1);
        color: var(--White);
        border: solid 2px var(--Color-primary);
        border-radius: 15px;
        transition: 1s;
      }

      div {
        display: flex;
        align-items: flex-start;
        height: 100%;
        width: 100%;

        p {
          font-size: 18px;
          text-align: start;
          padding-left: 25px;
          text-transform: capitalize;
        }
      }

      :hover {
        border: 5px solid var(--White-1);

        figure {
          img {
            border: solid 3px var(--Grey-2);
          }
        }

        span {
          border: solid 3px var(--Color-primary);
        }
      }
    }

    #ANSELL,
    #BRASCAMP,
    #DANNY,
    #DUPONT,
    #FUJIWARA,
    #HERCULES,
    #MSA,
    #MUCAMBO,
    #ORION,
    #TAYCO,
    #HONEYWELL,
    #MARLUVAS,
    #VOLK {
      figure {
        height: 910px;
      }
    }
  }

  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
    margin: 20px 0;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 30px;
      background-color: var(--Color-primary-negative);
      color: var(--White);
      border: solid 3px var(--Color-primary-negative);
      border-radius: 8px;
      margin: 5px 5px;
      cursor: pointer;

      a {
        padding: 8px 15px;
      }
    }

    li:nth-child(1) {
      width: 90px;
      height: 30px;

      a {
        padding: 8px 27px;
      }
    }
    li:nth-last-child(1) {
      width: 90px;
      height: 30px;

      a {
        padding: 8px 27px;
      }
    }

    li:hover {
      border: solid 3px var(--Color-primary);
    }

    .selected {
      border: solid 3px var(--Color-primary);
    }
  }

  @media (min-width: 768px) {
    #listProducts {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  @media (min-width: 768px) {
    #listProducts {
      max-width: 1440px;
    }
  }
`;

import { AnimatePresence } from "framer-motion"
import { Route, Routes } from "react-router-dom"
import Company from "../Pages/Company"
import Contact from "../Pages/Contact"
import Home from "../Pages/Home"
import Products from "../Pages/Products"

export const RoutesPages = () => {

    return (
        <AnimatePresence>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route exact path="/Contato" element={<Contact/>}/>
                <Route exact path="/Produtos" element={<Products/>}/>
                <Route exact path="/Empresa" element={<Company/>}/>
            </Routes>
        </AnimatePresence>
    )
}
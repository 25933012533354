import { Footer } from "../../Components/Footer";
import { ContactUs } from "../../Components/Form";
import { Header } from "../../Components/Header";
import { Location } from "../../Components/location";
import { Div, DivSpan, Main } from "./styles";
import email from "../../assets/img/mail.png";
import location from "../../assets/img/location2.png";
import whatsapp from "../../assets/img/whatsapp.png";
import phone from "../../assets/img/telephone.png";
import mail from "../../assets/img/mail.png";

const Contact = () => {
  return (
    <>
      <Header />
      <Div>
        <figure>
          <img src={email} alt="Imagem do contato" />
        </figure>
        <h1>Contato</h1>
      </Div>
      <Main>
        <section id="contato">
          <ContactUs />
        </section>
        <section id="ContainerMapa">
          <h2>Localização</h2>
          <Location />
          <DivSpan>
            <div>
              <figure>
                <img src={location} alt="imagem da localização" />
                <a
                  href="https://www.google.com/maps/place/Estr.+do+Rio+Grande,+1621+-+Taquara,+Rio+de+Janeiro+-+RJ,+22720-010"
                  target="_blank"
                  rel="noreferrer"
                >
                  Estrada do Rio Grande, 1621 - Taquara
                </a>
              </figure>
              <figure>
                <img src={phone} alt="Imagem do telefone" />
                <a href="tel:+552130213886">+55 (21)3021-3886</a>
              </figure>
              <figure>
                <img src={phone} alt="Imagem do telefone" />
                <a href="tel:+552122094257">+55 (21)2209-4257</a>
              </figure>
              <figure>
                <img src={whatsapp} alt="Imagem do whatsapp" />
                <a href="tel:+5521970850986">+55 (21)97085-0986</a>
              </figure>
            </div>
            <div>
              <figure>
                <img src={mail} alt="Imagem do email" />
                <a href="mailto: vendas@barramarnautica.com.br?subject=subject text">
                  vendas@barramarnautica.com.br
                </a>
              </figure>
              <figure>
                <img src={mail} alt="Imagem do email" />
                <a href="mailto: barramar.contato@barramarnautica.com.br?subject=subject text">
                  barramar.contato@barramarnautica.com.br
                </a>
              </figure>
              <figure>
                <img src={mail} alt="Imagem do email" />
                <a href="mailto: financeiro@barramarnautica.com.br?subject=subject text">
                  financeiro@barramarnautica.com.br
                </a>
              </figure>
            </div>
          </DivSpan>
        </section>
      </Main>
      <Footer />
    </>
  );
};

export default Contact;

import styled from "styled-components";

export const Div = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-top: 40px;

  figure {
    margin-right: 10px;

    img {
      width: 30px;
    }
  }

  h1 {
    font-family: "Lobster", cursive;
    color: var(--Black-1);
  }

  @media (min-width: 768px) {
    padding: 0 45px;
  }
`;
export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 15px;

  h2 {
    padding: 20px 0;
    font-size: 20px;
    color: var(--Black-1);
  }

  #contato {
    top: 50%;
    left: 50%;
    width: 100%;
    border-radius: 10px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 50px;

    .MuiLoadingButton-root {
      border: 1px solid var(--Color-primary-focus);
      color: var(--Color-primary-focus);
      width: 150px;
      height: 40px;
      border-radius: 7px;
      margin: 10px;
      padding-bottom: 30px;

      div {
        width: auto;
      }
    }

    div {
      form {
        padding-bottom: 25px;
      }
    }
  }

  #ContainerMapa {
    width: 100%;

    #mapa {
      #mapaHeight {
        border: solid 1px var(--Black-1);
        height: 300px;
      }
    }

    div {
      div,
      div figure,
      div figure a {
        width: 100%;
      }
    }
  }

  .MuiContainer-root {
    padding: 0;

    form {
      div {
        width: 95%;
        align-items: center;

        label {
          padding-left: 3%;
        }
      }
    }
  }

  @media (min-width: 425px) {
    #contato,
    #ContainerMapa,
    #ContainerMapa #mapa {
      max-width: 400px;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0 35px;

    #contato {
      max-width: none;
      margin-right: 40px;
      margin-bottom: 0;
    }

    #contato,
    #ContainerMapa,
    #ContainerMapa #mapa {
      max-width: 500px;
    }
  }

  @media (min-width: 1024px) {
    padding: 0 70px;

    #contato {
      margin-right: 80px;
      max-width: 600px;
    }

    #ContainerMapa {
      #mapa {
        #mapaHeight {
          height: 400px;
        }
      }

      a {
        font-size: 14px;
      }
    }

    #contato,
    #ContainerMapa,
    #ContainerMapa #mapa {
      max-width: 500px;
    }
  }

  @media (min-width: 1440px) {
    #contato {
      margin-right: 0;
    }

    #ContainerMapa {
      #mapa {
        #mapaHeight {
          height: 500px;
        }
      }

      a {
        font-size: 16px;
      }
    }

    #contato,
    #ContainerMapa,
    #ContainerMapa #mapa {
      max-width: 600px;
    }
  }
`;

export const DivSpan = styled.div`
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    margin-top: 30px;

    figure {
      margin: 3px 0;

      img {
        width: 12px;
        margin-right: 3px;
      }

      a {
        color: var(--Black-1);
        text-decoration: none;
        cursor: pointer;
        font-size: 12px;

        :hover {
          color: var(--Color-primary-focus);
        }
      }
    }
  }

  @media (min-width: 425px) {
    div {
      width: 100%;
    }
  }
`;
